import React, { useRef, useState } from "react";
import { sendChatMessage } from "../../api/chat";
import EmojiPicker, { Theme, EmojiClickData } from "emoji-picker-react";
import { Popover } from "@headlessui/react";
import { usePopper } from "react-popper";

export function ChatInput() {
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [messageValue, setMessageValue] = useState<string>("");
  const [emojiButtonElement, setEmojiButtonElement] =
    useState<HTMLButtonElement | null>(null);
  const [emojiPanelElement, setEmojiPanelElement] =
    useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(
    emojiButtonElement,
    emojiPanelElement,
  );

  const handleSubmitMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (messageValue && messageValue !== "") {
      sendChatMessage(messageValue);
      // Clear textinput
      setMessageValue("");
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    if (messageRef.current) {
      setMessageValue(
        (prev) =>
          prev.substr(0, messageRef?.current?.selectionStart) +
          emojiData.emoji +
          prev.substr(messageRef?.current?.selectionEnd || 0),
      );
    }
  };

  const handleMessageKeyDown = (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmitMessage(e);
    }
  };

  return (
    <div className="flex items-end">
      <form className="w-full" onSubmit={handleSubmitMessage}>
        <div className="flex items-center px-3 py-2 bg-gray-700">
          <Popover className="relative">
            <Popover.Button
              ref={setEmojiButtonElement}
              className="p-2  rounded-lg cursor-pointer  text-gray-400 hover:text-white resize-none hover:bg-gray-600"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.408 7.5h.01m-6.876 0h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM4.6 11a5.5 5.5 0 0 0 10.81 0H4.6Z"
                />
              </svg>
              <span className="sr-only">Add emoji</span>
            </Popover.Button>

            <Popover.Panel
              ref={setEmojiPanelElement}
              className="absolute z-10"
              style={styles.popper}
              {...attributes.popper}
            >
              <EmojiPicker theme={Theme.DARK} onEmojiClick={handleEmojiClick} />
            </Popover.Panel>
          </Popover>
          <textarea
            cols={1}
            ref={messageRef}
            value={messageValue}
            onChange={(e) => setMessageValue(e.target.value)}
            onKeyDown={handleMessageKeyDown}
            className="block mx-4 pt-2.5 w-full text-sm rounded-lg border  bg-gray-800 border-gray-600 placeholder-gray-400 text-white focus:ring-sky-500 focus:border-sky-500 h-11 resize-none"
            placeholder="Your message..."
          ></textarea>
          <button
            type="submit"
            className="inline-flex justify-center p-2  rounded-full cursor-pointer text-sky-500 hover:bg-gray-600"
          >
            <svg
              className="w-5 h-5 rotate-90"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 20"
            >
              <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
            </svg>
            <span className="sr-only">Send message</span>
          </button>
        </div>
      </form>
    </div>
  );
}
