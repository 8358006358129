import React, { useEffect } from "react";
import { SignIn } from "./signIn";
import { userName } from "./state/global";
import App from "./App";
import { closeWebSocketClient, createWebSocketClient } from "./api/api";
import { effect } from "@preact/signals-react";

effect(() => {
  if (userName.value) {
    createWebSocketClient();
  } else {
    closeWebSocketClient();
  }
});

export function AppWrapper() {
  return (
    <>
      {userName.value && <App />}
      {!userName.value && <SignIn />}
    </>
  );
}
