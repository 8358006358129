import React, { useRef, useEffect } from "react";
import { messages } from "../../state/chat";
import { ChatInput } from "./chatInput";
import { ChatMessage } from "./chatMessage";

export function Chat() {
  const messageListBottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messages.value?.length) {
      messageListBottomRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages.value?.length, messageListBottomRef]);

  return (
    <div className="h-[372px] md:h-full overflow-hidden relative">
      <div className="h-[calc(100%-58px)] overflow-y-auto">
        <ul className="divide-y divide-gray-700">
          {messages.value.map((message) => (
            <ChatMessage key={message.id} message={message} />
          ))}
        </ul>
        {messages.value.length > 0 && (
          <button
            onClick={() => {
              messages.value = [];
            }}
            className="text-xs absolute bottom-[60px] left-[50%] text-slate-500 hover:text-white hover:underline -translate-x-1/2"
          >
            Clear chat
          </button>
        )}
        <div ref={messageListBottomRef} />
      </div>
      <ChatInput />
    </div>
  );
}
