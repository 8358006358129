import React, { useEffect } from "react";
import { PopoverTrigger, Popover, PopoverContent } from "../popover";
import { UsersIcon } from "@heroicons/react/20/solid";
import { getOthersPlaylists } from "../../api/playlist";
import { connectionState } from "../../state/global";
import {
  activePlaylistId,
  otherPlaylistDescriptions,
} from "../../state/playlists";

export function OtherPlaylistButton() {
  const [isOthersListOpen, setIsOthersListOpen] = React.useState(false);

  useEffect(() => {
    if (connectionState.value === "Connected") {
      getOthersPlaylists();
    }
  }, [connectionState.value]);

  return (
    <Popover
      open={isOthersListOpen}
      onOpenChange={() => setIsOthersListOpen((v) => !v)}
      placement="right"
    >
      <PopoverTrigger
        onClick={() => setIsOthersListOpen((v) => !v)}
        className="mt-3 rounded-lg bg-slate-700/50 p-2 text-white shadow-sm hover:bg-slate-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <UsersIcon className="h-5 w-5" aria-hidden="true" />
      </PopoverTrigger>
      <PopoverContent className="border border-slate-600 rounded-lg w-60 bg-slate-700  text-white z-20">
        <div className="max-h-[400px] overflow-y-auto">
          {otherPlaylistDescriptions.value.length === 0 && (
            <div className="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium rounded-lg focus:z-10 hover:bg-slate-600 italic hover:text-white focus:text-white whitespace-nowrap">
              No playlists by others
            </div>
          )}
          {otherPlaylistDescriptions.value.map((playlist) => (
            <button
              onClick={() => {
                activePlaylistId.value = playlist.title + playlist.listener;
              }}
              key={`${playlist.listener}-${playlist.title}`}
              type="button"
              className="relative inline-flex items-center w-full px-4 py-2 text-sm font-bold rounded-lg focus:z-10 hover:bg-slate-600 hover:text-white focus:text-white whitespace-nowrap truncate"
            >
              {playlist.title}
              <span className="text-xs font-medium ml-1 text-slate-400">
                - {playlist.listener}
              </span>
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}
